/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/2155220468.jpg",
    alt: "Raspberry Pi",
    title: "Raspberry Pi"
  }), "Que interesante ", React.createElement(_components.a, {
    href: "http://spectrum.ieee.org/podcast/geek-life/hands-on/arduinos-playmate"
  }, "la entrevista"), " sobre éste pequeño ordenador que he encontrado en ieee spectrum, entrevistan en este caso al fundador de ", React.createElement(_components.a, {
    href: "http://www.raspberrypi.org/"
  }, "Raspberry Pi"), ", Eben Upton, que además es diseñador de chips en Broadcom. Cosas que destacaría, la pregunta sobre como es de abierto Raspberry Pi, por ejemplo en comparación con ", React.createElement(_components.a, {
    href: "http://arduino.cc/"
  }, "Arduino"), ", que se remarque el uso de estándares como el caso de la alimentación por microUSB y los nuevos usos que se le están buscando a la placa, ponen como ejemplo su uso en sistemas de control."), "\n", React.createElement(_components.p, null, "Personalmente Raspberry me gusta pero le hecho en falta un poco de esa libertad que si que demuestra Arduino, podrían empezar por compartir los esquemas de la placa, por otra parte y siguiendo con la comparación con Arduino, Raspberry demuestra una mayor utilización de estándares, en Arduino no son estándares ni siquiera los pines de entrada y salida."), "\n", React.createElement(_components.p, null, "Raspberry Pi aunque es una plataforma de desarrollo muy interesante por sus capacidades y su bajo coste, no se puede considerar una plataforma de hardware libre como Arduino, espero sinceramente que éste sea el primer paso para que aparezcan más plataformas hardware de este tipo que sí tengan una filosofía de libertad de base, este podría ser un gran salto para el hardware libre, que pasaría de dispositivos de hardware más o menos simples a algo tan complejo como un verdadero ordenador."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
